// Framework dependencies
import { addDays, addMonths } from 'date-fns';

// Package dependencies
import {
  AddressSubSchema,
  SuperSchema,
  INPUT_VALIDATORS,
} from '@updater/ui-informant';

// Local dependencies
import { WHO_IS_MOVING_CARD_OPTIONS } from '../constants/copy';

/**
 * @const {Object} formTypeConst
 * @description Informant data partial schema for whosmoving component
 */
export const formTypeConst = WHO_IS_MOVING_CARD_OPTIONS.map(
  ({ value, title }) => ({ const: value, title })
);

/**
 * Returns a pristine copy of the FYM Informant schema.
 *
 * @returns SuperSchema
 */
function getSchema(): SuperSchema {
  return {
    $id: 'fym',
    type: 'object',
    properties: {
      whosMoving: {
        type: 'string',
        oneOf: formTypeConst,
        ui: {
          component: 'WhoIsMovingCard',
          messages: {
            required: 'Please select an option.',
          },
          componentProps: { controlSize: 'l' },
        },
      },
      businessName: {
        type: 'string',
        minLength: 1,
        maxLength: 255,
        pattern: INPUT_VALIDATORS.ONLY_LETTERS_AND_SPACES,
        ui: {
          label: 'Business Name',
          messages: {
            required: 'Please your business name',
            minLength: 'Business name is required',
            pattern:
              'The Business Name field allows only letters and spaces. Please remove all other characters from this field.',
          },
        },
      },
      firstName: {
        type: 'string',
        minLength: 1,
        maxLength: 255,
        pattern: INPUT_VALIDATORS.ONLY_LETTERS_AND_SPACES,
        ui: {
          label: 'First Name',
          messages: {
            required: 'Please enter a first name.',
            minLength: 'First name is required.',
            pattern:
              'The First Name field allows only letters and spaces. Please remove all other characters from this field.',
          },
        },
      },
      lastName: {
        type: 'string',
        minLength: 1,
        maxLength: 255,
        pattern: INPUT_VALIDATORS.NAME,
        ui: {
          label: 'Last Name',
          messages: {
            required: 'Please enter a last name.',
            minLength: 'Last name is required.',
            pattern:
              'The Last Name field allows only letters, spaces, and the following characters ( - ) and must begin and end with a letter.',
          },
        },
      },
      middleName: {
        type: 'string',
        pattern: INPUT_VALIDATORS.NOT_REQUIRED(INPUT_VALIDATORS.LETTERS),
        ui: {
          label: 'Middle Name',
          messages: {
            pattern: 'The Middle Name field allows only letters.',
          },
          component: 'text',
        },
      },
      suffixName: {
        type: 'string',
        oneOf: [
          {
            title: '',
            const: ' ',
          },
          {
            title: 'I',
            const: 'I',
          },
          {
            title: 'II',
            const: 'II',
          },
          {
            title: 'III',
            const: 'III',
          },
          {
            title: 'IV',
            const: 'IV',
          },
          {
            title: 'V',
            const: 'V',
          },
          {
            title: 'JR',
            const: 'JR',
          },
          {
            title: 'SR',
            const: 'SR',
          },
          {
            title: 'MD',
            const: 'MD',
          },
          {
            title: 'ATTY',
            const: 'ATTY',
          },
          {
            title: 'CEO',
            const: 'CEO',
          },
          {
            title: 'CFO',
            const: 'CFO',
          },
          {
            title: 'CIO',
            const: 'CIO',
          },
          {
            title: 'CPA',
            const: 'CPA',
          },
          {
            title: 'DC',
            const: 'DC',
          },
          {
            title: 'DDS',
            const: 'DDS',
          },
          {
            title: 'DVM',
            const: 'DVM',
          },
          {
            title: 'ESQ',
            const: 'ESQ',
          },
          {
            title: 'PHD',
            const: 'PHD',
          },
          {
            title: 'PRES',
            const: 'PRES',
          },
          {
            title: 'RET',
            const: 'RET',
          },
          {
            title: 'VP',
            const: 'VP',
          },
        ],
        ui: {
          component: 'select',
          label: 'Suffix',
        },
      },
      forwardMailDate: {
        type: 'string',
        minLength: 10,
        ui: {
          component: 'datePicker',
          componentProps: {
            value: addDays(new Date(), 10),
            minDate: addDays(new Date(), 10), // minimum is 10 days from current day
            maxDate: addMonths(new Date(), 3), // maximum is 3 months from today
          },
          label: 'Date',
          subHeading:
            'It can take 2 weeks to start receiving mail at your new address.',
          messages: {
            required: 'Please select a date to start your mail forwarding.',
            minLength: 'Start date is required to proceed',
          },
        },
      },
      isMoveTemporary: {
        type: 'string',
        oneOf: [
          {
            const: 'no',
            title: 'No',
          },
          {
            const: 'yes',
            title: 'Yes',
          },
        ],
        ui: {
          component: 'radiolist',
          componentProps: { controlSize: 'l' },
        },
      },

      review: {
        type: 'string',
        ui: {
          component: 'WrapperReview',
        },
      },
      businessCardWrapper: {
        type: 'string',
        ui: {
          label: 'Business Name',
          component: 'BusinessCardWrapper',
        },
      },
    },

    required: [
      'whosMoving',
      'firstName',
      'lastName',
      'forwardMailDate',
      'isMoveTemporary',
    ],

    allOf: [
      {
        if: {
          properties: {
            addressesNormalized: {
              const: 'true',
            },
          },
        },
        then: {
          properties: {
            confirmAddresses: {
              type: 'string',
              ui: {
                component: 'ConfirmAddresses',
              },
            },
          },
        },
      },
      {
        if: {
          allOf: [
            {
              properties: {
                isFileEdited: {
                  const: 'false',
                },
              },
            },
            {
              properties: {
                isNewForm: {
                  const: 'false',
                },
              },
            },
          ],
        },
        then: {
          properties: {
            currentAddress: {
              ...AddressSubSchema,
              ui: {
                component: 'address',
                componentProps: {
                  allowMilitary: false,
                  allowCommercial: false,
                  allowAptNeeded: true,
                  allowInvalid: true,
                  setForced: true,
                  allowInvalidAddressSubmission: false,
                  verifyAddressDescriptionText:
                    'In order to complete your mail forwarding request, you must use a valid address. We recommend using the suggested address below.',
                  warningDescriptionText:
                    'We cannot complete your mail forwarding request without a valid address. Please click ‘Edit address’ to correct your address submission.',
                  militaryErrorMsg:
                    'You cannot forward mail from a military address. If you believe this is a mistake, email help@updater.com',
                  commercialErrorMsg:
                    'You cannot forward mail from a commercial address. If you believe this is a mistake, email help@updater.com',
                },
              },
            },
            newAddress: {
              ...AddressSubSchema,
              ui: {
                component: 'AddressWidgetWrapper',
                componentProps: {
                  allowMilitary: false,
                  allowCommercial: false,
                  allowAptNeeded: true,
                  allowInvalid: true,
                  setForced: true,
                  allowInvalidAddressSubmission: false,
                  verifyAddressDescriptionText:
                    'In order to complete your mail forwarding request, you must use a valid address. We recommend using the suggested address below.',
                  warningDescriptionText:
                    'We cannot complete your mail forwarding request without a valid address. Please click ‘Edit address’ to correct your address submission.',
                  militaryErrorMsg:
                    'You cannot forward mail to a military address. If you believe this is a mistake, email help@updater.com',
                  commercialErrorMsg:
                    'You cannot forward mail to a commercial address. If you believe this is a mistake, email help@updater.com',
                },
              },
            },
          },
        },
      },
      {
        if: {
          properties: {
            isMoveTemporary: {
              const: 'yes',
            },
          },
        },
        then: {
          properties: {
            stopForwardMailDate: {
              type: 'string',
              minLength: 10,
              ui: {
                component: 'datePicker',
                label: 'Date',
                messages: {
                  required: 'Please select a date to end your mail forwarding.',
                  minLength: 'Stop date is required to proceed.',
                  type: 'Stop date is required to proceed.',
                },
                validate: 'validateStopForwardingDate',
              },
            },
          },
          required: ['stopForwardMailDate'],
        },
      },
      {
        if: {
          properties: {
            whosMoving: {
              const: 'business',
            },
          },
        },
        then: {
          properties: {
            businessName: {
              type: 'string',
              minLength: 1,
              ui: {
                label: 'Business Name',
                messages: {
                  required: 'Business name is required.',
                  minLength: 'Business name is required.',
                },
                validate: 'validateBusinessName',
              },
            },
          },
          required: ['businessName'],
        },
      },
    ],
  };
}

/**
 * Dynamically builds the schema based on the start forwarding date.
 * Note: The default starting date comes from the `user` object
 *
 * @param startForwardingDate The date mail will start forwarding - set by `user` object.
 * @returns SuperSchema
 */
export function buildSchema(startForwardingDate?: string): SuperSchema {
  // Ensure we always have a pristine copy
  const schema = getSchema();

  // Find the definition of the 'stopForwardMailDate' field only engaged when move is `temporary`
  const stopForwardRuleName = 'stopForwardMailDate';
  const stopForwardRule = schema?.allOf?.find((rule: SuperSchema) => {
    if (rule.then?.properties === undefined) return undefined;
    const stopProp = rule.then.properties;
    return stopForwardRuleName in stopProp;
  });

  if (startForwardingDate && stopForwardRule) {
    const startDate = new Date();
    const ruleConfig =
      stopForwardRule?.then?.properties?.stopForwardMailDate?.ui;

    if (ruleConfig) {
      ruleConfig.componentProps = {
        ...(ruleConfig.componentProps || {}),
        minDate: addDays(startDate, 15),
        maxDate: addMonths(startDate, 6),
      };
    }
  }

  return schema;
}
