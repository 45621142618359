import { FormType } from '../types';

export const WHO_IS_MOVING_CARD_VALUES: { [key: string]: FormType } = {
  INDIVIDUAL: `individual`,
  FAMILY: `family`,
  BUSINESS: `business`,
};

export const WHO_IS_MOVING_CARD_OPTIONS: {
  title: string;
  subtitle: string;
  value: FormType;
}[] = [
  {
    value: WHO_IS_MOVING_CARD_VALUES.INDIVIDUAL,
    title: 'Individual',
    subtitle:
      'Choose if you’re the only person moving. Receive mail under more than one name (e.g. maiden name)? Complete this form for each name.',
  },
  {
    value: WHO_IS_MOVING_CARD_VALUES.FAMILY,
    title: 'Family',
    subtitle:
      'Choose this option if everyone in your home has the same last name. Use individual forms for those with different last names.',
  },
  {
    value: WHO_IS_MOVING_CARD_VALUES.BUSINESS,
    title: 'Business',
    subtitle:
      'Choose this option if your business is moving from a non-commercial address. Individual or family forms that include a business address as the old address will not be processed.',
  },
];
export const MOVE_INFO_DISCLAIMER_CANCEL: string = 'Cancel mail forwarding';
export const FORWARDING_FORM_CANCEL: Array<string> = [
  'Your mail forwarding form for',
  'was cancelled',
];
export const FORWARDING_FORM_FILLED: Array<string> = [
  'Your mail forwarding form for',
  'was filled',
];
export const FORWARDING_FORM_UPDATED: Array<string> = [
  'Your mail forwarding form for',
  'was updated',
];
export const ALT_FORWARDING_FORM_TITLE: string =
  'Your mail forwarding form was filed!';
export const FORWARDING_FORM_SUBMIT_ERROR: string =
  "We couldn't submit your form. Please try again.";
export const RETRIEVING_FORM_ERROR: string =
  "We couldn't find any submitted forms. Please check the email and try again.";
export const USPS_DISCLAIMER: string =
  'USPS may take up to 12 days to process your request.';
export const FILE_NEW_FORM: string = 'File a new form';
export const FINISHED: string = 'Finish';
export const MOVE_INFO_DISCLAIMER_OTHER: string =
  'Want to file a form for different addresses?';
export const MOVE_INFO_DISCLAIMER_OTHER_LINE_2: string = 'Email us at ';
export const CONFIRM_ADDRESS_OPTIONS = [
  {
    title: 'Current Address',
    isCurrentAddress: true,
  },
  {
    title: 'New Address',
    isCurrentAddress: false,
  },
];
export const SUBMITTED_LABEL: string = 'Submitted on';
export const QUESTION_TITLE: string = 'Questions about your form?';
export const CONTACT_SUPPORT_TEXT = 'Email us at';
export const VALIDATE_STOP_FORWARDING_DATE_MESSAGE =
  "Choose a date that's at least 15 days after your start date";
export const VALIDATE_BUSINESS_NAME_UNIQUENESS_MESSAGE =
  'Business Name cannot be the same as first name or last name';
export const NEXT_LABEL = 'Next';
export const VALIDATE_CURRENT_AND_NEW_ADDRESSES = `Your current address and new address seem to match. Please check the addresses you've provided.`;
